import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import { useEffect } from "react";
import IBan from "../../components/Payment/IBan";
import Payment from "../../components/Payment/Payment";
import PaymentMethod from "../../components/Payment/PaymentMethod";
import PaymentMap from "../../components/Map/PaymentMap";
import { useOutletContext } from "react-router-dom";
import { privatSvg, easySvg, portmoneSvg, ipay, payHub, bankLviv, familniy, oschad, ukrposhta, pumb, radaBankPlat, appstore, playmarket, mtbBank } from "../../utils/svgPath";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import ConditionItem from "../../components/Condition/ConditionItem";


const ApplicationCli = () => {

  useEffect(() => {
        window.scrollTo(0,0);
  }, [])
  
  const paymentsOnline = {
    title: 'Оплата онлайн',
    subtitle: 'Оплата за електроенергію в інтернеті доступна через електронні системи:',
    payImgs: [
        {img: mtbBank, comission: false, path: 'https://mapi.xpaydirect.com/uk/frame/widget/banner?pid=a3accd38-decb-45e8-a14a-e0ce79876dde'},
        {img: privatSvg, comission: true, path: 'https://my-payments.privatbank.ua/mypayments/customauth/identification/fp/static?staticToken=597081034bcecc2e6f5bc8f953dcf45f3ryltqxs' },
        {img: portmoneSvg, comission: true, path: 'https://www.portmone.com.ua/r3/uk/terminal/index/index/id/16738' },
        {img: easySvg, comission: true, path: 'https://easypay.ua/ua/catalog/utility/lvov/lvovoblenergo' },
        {img: ipay, comission: false, path: 'https://www.ipay.ua/ua/bills/oplata-uslug-ooo-l-vovenergosbyt-online' },
        {img: payHub, comission: true, path: 'https://payhub.com.ua/#/payment/bf-4627'}
    ]
  }

  const paymentsStation = {
    title: 'Оплата в пунктах прийому платежів',
    subtitle: 'Оплата за електроенергію в пунктах прийому платежів доступна у таких банках та установах:',
    payImgs: [
        {img: privatSvg, comission: true, path: false },
        {img: oschad, comission: true, path: false },
        {img: pumb, comission: true, path: false },
        {img: bankLviv, comission: true, path: false },
        {img: familniy, comission: true, path: false },
        {img: radaBankPlat, comission: true, path: false },
        {img: ukrposhta, comission: true, path: false },
    ]
  }

  const paymentsTerminals = {
    title: 'Оплата в платіжних терміналах',
    subtitle: 'Оплата за електроенергію в платіжних терміналах доступна у таких банках та установах:',
    payImgs: [
        {img: privatSvg, comission: true, path: 'https://my-payments.privatbank.ua/mypayments/customauth/identification/fp/static?staticToken=597081034bcecc2e6f5bc8f953dcf45f3ryltqxs' },
        {img: easySvg, comission: true, path: 'https://easypay.ua/ua/catalog/utility/lvov/lvovoblenergo' },
        // {img: city24, comission: false, path: 'https://city24.ua/ua/oplata-kommunalnykh-uslug/lviv-region/lvivenerhozbut' },
        {img: ipay, comission: false, path: 'https://www.ipay.ua/ua/bills/oplata-uslug-ooo-l-vovenergosbyt-online' },
        {img: familniy, comission: true, path: false },
        {img: oschad, comission: true, path: false },
    ]
  }

  const mobileLEZ = {
    title: 'Мобільний застосунок ЛЕЗ',
    subtitle: 'Встановити за посиланням:',
    payImgs: [
        {img: appstore, comission: false, path: 'https://apps.apple.com/ua/app/%D0%BB%D0%B5%D0%B7/id1628053911?l=uk&utm_source=site&utm_medium=botton' },
        {img: playmarket, comission: false, path: 'https://play.google.com/store/apps/details?id=com.utilsuite.lez.prod&utm_source=site&utm_medium=botton&pli=1' },
    ]
  }

  const paymentList = [
    { description: 'Приватбанк',
      cordList: [
      [49.833774849341445, 24.01522079218136],
      [49.8298993945133, 23.995136411838903],
      [49.83831427289935, 24.00972762832701],
      [49.843904928444104, 24.01788154342331],
      [49.85276008928514, 24.03118529963306],
    ]},
    {
    description: 'Ощадбанк',
    cordList: [
      [49.83449089227774, 24.045004034105187],
      [49.83642847454973, 24.02861037322737],
      [49.84091229579051, 24.036335134897545],
      [49.8572387474694, 24.01882567511181],
      [49.82862231236222, 24.00878348494058]
    ]},
    {
    description: 'Пумб',
    cordList: [
      [49.83181045846336, 23.99063140442187],
      [49.83806474680992, 24.011147567194254],
      [49.83371974804647, 24.01329104688689],
      [49.84385747120351, 24.025641572734948],
      [49.84056623566681, 24.045749453661113]
    ]},
    {description: 'Банк Львів',
    cordList: [
      [49.835961984663996, 24.03266134123784],
      [49.84169208633143, 24.033117290940993],
      [49.823947040110525, 23.9766590561487],
    ]},
    {description: 'Банк Фамільний',
    cordList: [
      [49.83649964853058, 24.028168817366662],
      [49.83063131237663, 24.030893941661876],
    ]},
    {description: 'Радабанк',
    cordList: [
      [49.84657280859889, 24.028641616234225],
      [49.84250248527728, 24.026820872446855],
      [49.833307721958285, 24.068664219807037],
    ]},
    {description: 'Укрпошта',
    cordList: [
      [49.83714334939913, 24.004710493781097],
      [49.83528131439579, 24.014165381518307],
      [49.831880465123334, 24.023167650886606],
      [49.847158402528216, 24.023339313326055],
      [49.84889963445463, 23.996996266832806]
    ]},
  ];

  const terminalList = [
    { description: 'Платіжний термінал Приватбанк',
      cordList: [
      [49.832971708384, 24.038454625022595],
      [49.83535223048406, 24.015966985485303],
      [49.840334339304064, 24.02223262550905],
      [49.838341557375394, 24.02669582114241],
      [49.845869413728465, 24.018198583301977],
      [49.84553732713381, 24.03321895322193]
    ]},
    {
    description: 'Платіжний термінал EasyPay',
    cordList: [
      [49.83523786120131, 23.998715008678744],
      [49.83324486922662, 24.01167544215253],
      [49.8325251574951, 24.021803463012834],
      [49.844482055308134, 24.014937008192284],
      [49.83678790928656, 24.027039134813503]
    ]},
    {
    description: 'Платіжний термінал City24',
    cordList: [
      [49.92977623933763, 24.6401253737436],
    ]},
    {
    description: 'Платіжний термінал iPay',
    cordList: [
      [49.828748875882205, 24.026575450258836],
      [49.838713940789916, 24.05919111065644],
      [49.83771752669976, 24.02674711162935],
      [49.8424779864938, 24.026918772999863],
      [49.84059600027133, 24.00906599046644]
    ]},
  ];

  const [,,theme] = useOutletContext();

  return (
    <>
      {theme === 'light' && <div className="mont-block"></div>}
      <TitleBlock title={'Способи оплати'} type={'Для населення'}/>
      <IBan/>
      <Payment title={mobileLEZ.title} subtitle={mobileLEZ.subtitle} payImgs={mobileLEZ.payImgs}/> 
      <Payment title={paymentsOnline.title} subtitle={paymentsOnline.subtitle} payImgs={paymentsOnline.payImgs}/>
      <PaymentMethod/>
      <Payment title={paymentsStation.title} subtitle={paymentsStation.subtitle} payImgs={paymentsStation.payImgs}/>  
      <PaymentMap count={28} paymentList = {paymentList} type={'пункти прийому платежів'}/>
      <Payment title={paymentsTerminals.title} subtitle={paymentsTerminals.subtitle} payImgs={paymentsTerminals.payImgs}/>
      <PaymentMap count={17} paymentList = {terminalList} type={'платіжні термінали'}/>
      <Container className='payment-method cli'>
        <Row>
          <Col md={6}>
                <ConditionItem 
                     url={process.env.REACT_APP_SERVER_URL + '/media/664c602014a2d_Pobut_opys_rakhunku_(1).pdf'} name={"Переглянути"}
                     text = {'Приклад – роз’яснення рахунку за електричну енергію, який отримують побутові споживачі.'}
                     />
          </Col>
          <Col md={12} className='about-video__frame'>
              <iframe
                  width="100%"
                  height="560"
                  src={`https://www.youtube.com/embed/TaoRSUpJH24`}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="Embedded youtube"
              />
          </Col>
        </Row>
      </Container>
      <SliderNews/>
    </>
  )
}

export default ApplicationCli