import TitleBlock from "../../components/TitleBlock";
import SliderNews from "../../components/News/SliderNews";
import { useEffect, useRef, useState } from "react";
import OfferPower from "../../components/ComOffers/OfferPower";
import ComOffersType from "../../components/ComOffers/ComOffersType";
import ComTarifPlans from "../../components/ComOffers/ComTarifPlans";
import ComDowload from "../../components/ComOffers/ComDowload";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
// import Green from "../../components/GreenForm";
import { Link, useOutletContext } from "react-router-dom";
import ApiForm from "../../components/ApiForm";
import { getMenuList } from "../../Axios/getPage";

const CommercialOffers = () => {

  const [propositions, setPropositions] = useState({universal: {}, vilna: {}});

  useEffect(() => {
      let active = false;
        window.scrollTo(0,0);
        getMenuList('com-offers').then((response)=>{
          const [data] = response['hydra:member'];
          if(!active) {
            setPropositions({universal: data.menuItems[0], vilna: data.menuItems[1] })
          }
        })

        return () => {
          active = true;
        };
  }, [])

  const [,,theme] = useOutletContext();
  const [powerValue, SetPowerValue] = useState('');
  const [companyType, SetCompanyType] = useState('');
  const [tarifList, SetTarifList] = useState([]);
  const comTitle = useRef(null);

  function getPowerValue(e) {
    SetPowerValue(e.target.value);
  }

  function getOffer(type, name) {
    return type?.children?.find(element=>{
      return element.name === name;
    });
  }

  function getCompanyType(e) {
    switch(e.target.value) {
      case "Бізнес":
        SetTarifList(getOffer(propositions.universal, 'Бізнес')?.children.map(element =>{return {nameTariff: 'Базовий',nameCom: element.name,url: element.imageUrl}}))
        break;
      case "Бюджетні":
        SetTarifList(getOffer(propositions.universal, 'Бюджетні')?.children.map(element =>{return {nameTariff: 'Базовий',nameCom: element.name,url: element.imageUrl}}))
        break;
      case "Колективні":
        SetTarifList(getOffer(propositions.universal, 'Колективні')?.children.map(element =>{return {nameTariff: 'Базовий',nameCom: element.name,url: element.imageUrl}}))
        break;
      case "Непобутові споживачі":
        SetTarifList(getOffer(propositions.universal, 'Непобутові споживачі')?.children.map(element =>{return {nameTariff: 'Базовий',nameCom: element.name,url: element.imageUrl}}))
        break;
      case "Без постачальника":
        SetTarifList(getOffer(propositions.universal, 'Без постачальника ')?.children.map(element =>{return {nameTariff: 'Базовий',nameCom: element.name,url: element.imageUrl}}))
        break;
      default:
        SetTarifList([]);
        break;      
    }
    SetCompanyType(e.target.value);
    comTitle.current.scrollIntoView({block: "center", behavior: "smooth"})
  }

  // bold (true/false) - розмір .. також є thin класс в разі значення false, описані в розділі ComOffers component styles 

  const powerList = [
    {id: 1,  title: "> 50 кВт", bold: true },
    {id: 2,  title: "≤ 50 кВт", bold: true },

    {id: 3,  title: "Вільна ціна",
    content: ["На укладення договору за вільними цінами має право будь-який споживач, який відповідає критеріям обраної ним комерційної пропозиції. Укладення договору за вільними цінами здійснюється шляхом подачі заяви-приєднання до договору."],
    bold: false },
    {id: 4,  title: "Універсальна послуга", 
     content: ["Універсальна послуга — постачання електричної енергії побутовим та малим непобутовим споживачам, що гарантує їхні права бути забезпеченими електричною енергією. При цьому, універсальну послугу надають постачальники електричної енергії, на яких покладена дана функція."],
    bold: false }
  ]
  
  const tarifUpperBusiness = getOffer(propositions.vilna, 'Бізнес')?.children.map(element =>{
    return {
      nameTariff: 'Базовий',
      isOsr: element.orders > 0 ? true : false,
      payment: element.slug,
      nameCom: element.name,
      url: element.imageUrl
    }
  })

  return (
    <>
      {theme === 'light' && <div className="mont-block"></div>}
      <TitleBlock title={'Комерційні пропозиції'} type={'Комерційні пропозиції за вільними цінами та універсальна послуга для клієнтів ТОВ «Львівенергозбут»'} addClass={'com-top'}/>

      <TitleBlock subtitle={'Оберіть потужність'} type={'Будь ласка, оберіть потужність'}/>
      <OfferPower checkList = {powerList} getValue = {getPowerValue} />
      {!!powerValue.length && 
      <>
      <TitleBlock subtitle={'Оберіть тип Вашої компанії'} type={'Будь ласка, спочатку оберіть тип Вашої компанії'}/>
      <ComOffersType typePower = {powerValue} typeCompany={companyType} getCompanyType = {getCompanyType}/>
      <Container ref = {comTitle} >
      {((powerValue === 'Вільна ціна' || powerValue === '> 50 кВт') && companyType === 'Бюджетні') ? <></> 
        : !!companyType.length && <Row><Col md={12}> <h3 className="comerc-title">Ваша комерційна пропозиція:</h3></Col>
        </Row>
        }
       </Container>
      {((powerValue === 'Вільна ціна' || powerValue === '> 50 кВт') && companyType === 'Бізнес') &&  
        <ComTarifPlans planList = {tarifUpperBusiness}/>
      }
      {(powerValue === 'Універсальна послуга' || powerValue === '≤ 50 кВт') && 
        <> 
        <ComTarifPlans planListLong = {tarifList}/>
          <Container>
          <Row><Col md={12}> <h3 className="comerc-title">Додаткові матеріали:</h3></Col>
          </Row>
          <Row className="download-block">
            <Col md={6} className="download-block__item">
              <ComDowload url={'/docx/com_offer/Dogovir_PYP_maluj_nepobytovuj.docx'} content = {'Договір про постачання електричної енергії постачальником універсальних послуг'}/>
            </Col>
            <Col md={6} className="download-block__item">
              <ComDowload url={'/docx/com_offer/Zaiava-pryiednannia.doc'} content = {'Заява-приєднання до договору про постачання електричної енергії споживачу'}/>
            </Col>
          </Row>  
        </Container>
        </>
      }
      {(powerValue === 'Вільна ціна' || powerValue === '> 50 кВт') && 
        <Container>
          <Row><Col md={12}> <h3 className="comerc-title">Додаткові матеріали:</h3></Col>
          </Row>
        <Row className="download-block">
          <Col md={6} className="download-block__item">
            <ComDowload url={'/docx/com_offer/Dogovir_vilna_cina.pdf'} content = {'Договір про постачання електричної енергії споживачу'} target={'_blank'}/>
          </Col>
          <Col md={6} className="download-block__item">
            <ComDowload url={'/docx/com_offer/Zaiava-pryiednannia_do_dohovoru_pro_postachania_elektrychnoi_enerhii_spozhyvachu.doc'} content = {'Заява-приєднання до договору про постачання електричної енергії споживачу'}/>
          </Col>
          <Col md={6} className="download-block__item">
            <ComDowload url={'/page/indyv_kom_prop'} content = {'Індивідуальна комерційна пропозиція'}/>
          </Col>
          <Col md={6} className="download-block__item">
            <ComDowload url={'/docx/com_offer/Umovy_vstanovlennya_ta_koryguvannya_dogovirnyx_obsyagiv_postachannya_elektrychnoyi_energiyi.pdf'} content = {'Умови встановлення та коригування договірних обсягів постачання електричної енергії'} target={'_blank'}/>
          </Col>
        </Row>  
      </Container>
      }
      </> }

      {(powerValue === 'Вільна ціна' || powerValue === '> 50 кВт') && 
      <Container>
        <Row className="arhiv-link"><Col md={5}>
          <Link to={'/page/komerc_vilni_ciny_archiv '} className="download-link">
            <img src='/img/download-icon.svg' alt=""/>
            <p className="name-link">Переглянути</p>
            <p>Архів вільних цін</p>
          </Link>
        </Col>
      </Row>
      </Container>}

      {(powerValue === 'Універсальна послуга' || powerValue === '≤ 50 кВт') && 
      <Container>
        <Row className="arhiv-link"><Col md={5}>
          <Link to={'/page/komers_mnepobut_archiv '} className="download-link">
            <img src='/img/download-icon.svg' alt=""/>
            <p className="name-link">Переглянути</p>
            <p>Архів універсальної послуги</p>
          </Link>
        </Col>
      </Row>
      </Container>}

      <ApiForm id={20}/>
       {/* <Green optionsActivity={optionsActivity}/> */}
    <SliderNews/> 
  </>
  )
}

export default CommercialOffers