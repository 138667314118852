import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import PaymentItem from './PaymentItem';
import { monobank, pumb, radaBank, mtb360, oschad } from '../../utils/svgPath';

const PaymentMethod = () => {
  return (
        <Container className='payment-method'>
            <h3 className='payment-method__title'>Аби оплатити за електроенергію необхідно:</h3>
            <Row>
                <Col md={3} className='payment-method__banner'>
                    <div className="banner-inner">
                        <img src="/img/payment-method-1-1.png" alt="" />
                        <img src="/img/payment-method-1-1.png" alt="" />
                        <img src="/img/payment-method-1-1.png" alt="" />
                        <img src="/img/payment-method-1-1.png" alt="" />
                        <img src="/img/payment-method-1-1.png" alt="" />
                        <img src="/img/payment-method-1-1.png" alt="" />
                    </div>
                    <p>Клікнути на банер обраної Вами платіжної системи</p>
                </Col>
                <Col md={3} className='payment-method__item'>
                    <img src="/img/payment-method-2.png" alt="" />
                    <p>Ввести десятизначний код, який є на Вашій квитанції про оплату спожитої електроенергії</p>
                </Col>
                <Col md={3} className='payment-method__item'>
                    <img src="/img/payment-method-3.png" alt="" />
                    <p>Ввести дані Вашої платіжної картки будь-якого банку</p>
                </Col>
                <Col md={3} className='payment-method__item'>
                    <img src="/img/payment-method-4.png" alt="ticket"/>
                    <p>Здійснити оплату</p>
                </Col>
            </Row>
            <Row>
                <Col md={3} className='payment-ticket'>
                    <img src="/img/payment-tiket.png" alt="" />
                </Col>
                <Col md={6} className='payment-yellow'>
                    <p>Підтвердженням проведеної Вами оплати за спожиту електроенергію є квитанція, яку Ви можете роздрукувати безпосередньо з сайту, а також SMS-повідомлення,
                        якщо така послуга активована для Вашої картки</p>
                </Col>
            </Row>
            <Row className='align-items-center payment-method__inner'>
               <Col md={3} className='payment-method__block pay-inner'>
                    <p>Окрім цього, Ви можете сплатити за електроенергію без комісії з допомогою мобільних застосунків:</p>
                </Col>
                <Col md={3} className='pay-inner'>
                <PaymentItem
                    type= {monobank}
                    isCommision={false}
                    path= 'https://www.monobank.com.ua/'
                />
                </Col>
                <Col md={3} className='pay-inner'>
                <PaymentItem
                    type= {pumb}
                    isCommision={true}
                    path= 'https://www.pumb.ua/pumbonline'
                />
                </Col>
                <Col md={3} className='pay-inner'>
                <PaymentItem
                    type= {radaBank}
                    isCommision={true}
                    path= 'https://www.radabank.com.ua/rb24-online/rb24'
                />
                </Col>
                <Col md={3} className='pay-inner'>
                <PaymentItem
                    type= {oschad}
                    isCommision={true}
                    path= 'https://www.oschadbank.ua/mob-oschad'
                />
                </Col>
                <Col md={3} className='pay-inner'>
                <PaymentItem
                    type= {mtb360}
                    isCommision={true}
                    path= 'https://mtb.ua/mtb-360'
                />
                </Col>
            </Row>
    </Container>
    
  )
}

export default PaymentMethod